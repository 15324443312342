const corp_partners = [
    {
        "name": "Starbucks"
    },
    {
        "name": "Zoom"
    },
    {
        "name": "Phoenix Childrens Hospital"
    },
    {
        "name": "Bank of the West"
    }
]

module.exports = corp_partners