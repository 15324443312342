import React from 'react';

/**
 * Main App Component
 */

import "../scss/app.scss"
import "@vetixy/circular-std"
import LuminosityLogo from "../img/logo.png"
import GSVLogo from "../img/gsv_logo.svg"
import Tilt from "./Tilt"
import CorpPartners from "../model/corp"

const App = () => {

    const tiltOptions = {
        scale: 1.2,
        speed: 10000,
        max: 30
    };


    return (
        <div className="app_container">
            <div className={"logos_container"}>
                <img src={LuminosityLogo} className={"luminosity_logo"} />
                <div className="divider"></div>
                <img src={GSVLogo} className={"gsv_logo"} />
            </div>
            <center>
                <p className="intro">At Luminosity, students design, build, and deploy novel solutions to address some of the world’s most pressing challenges.</p>
            </center>
            <div className="section">
                <h1 className="section_title">Corporate Projects</h1>
                <p className="section_desc">Tap to know more.</p>
                <div className="section_content">
                    <div className={"cards"}>
                        {CorpPartners.map((it, value) => {
                            return (
                                <Tilt className={"card"} options={tiltOptions}>
                                    {CorpPartners[value].name}
                                </Tilt>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App;